import React, { useState } from 'react';
import SectionTitle from '../../components/SectionTitle'
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import sImg1 from '../../images/story/story1.png'
import sImg2 from '../../images/story/story2.png'
import sImg3 from '../../images/story/story3.png'
import sImg4 from '../../images/story/story4.png'

import sIcon1 from '../../images/story/1.png'
import sIcon2 from '../../images/story/2.png'
import sIcon3 from '../../images/story/3.png'
import sIcon4 from '../../images/story/4.png'


import Shape from '../../images/story/shape.jpg'


const StorySection = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }


    const Tabs = [
        {
            Id: '1',
            tabItem: 'First Meet',
        },
        {
            Id: '2',
            tabItem: 'First Date',
        },
        {
            Id: '3',
            tabItem: 'Marriage Proposal',
        },
        {
            Id: '4',
            tabItem: 'Our Engagement',
        }
    ]

    const Story = [
        {
            Id: '1',
            sImg: sImg1,
            sIcon:sIcon1,
            Shape:Shape,
            sTitle:'First Time We Met',
            date:'2016',
            description:"Our paths originally crossed in that Bible study group, but it wasn't until after a few years that we realized the depth of our affections for one another."
        },
        {
            Id: '2',
            sImg: sImg2,
            sIcon:sIcon2,
            Shape:Shape,
            sTitle:'First Date',
            date:'April 27, 2020',
            description:'With each passing day, we discovered more and more reasons to love each other and our relationship only grew stronger. Our love was rooted in our faith and our shared commitment to our beliefs, and this shared foundation provided a sense of stability and comfort in our lives.'
        },
        {
            Id: '3',
            sImg: sImg3,
            sIcon:sIcon3,
            Shape:Shape,
            sTitle:'Marriage Proposal',
            date:'May 15, 2022',
            description:'The proposal was truly a beautiful and intimate moment. Surrounded by close friends who have supported us throughout our relationship, we felt overwhelmed with love and happiness as our significant other got down on one knee and asked us to be their partner for life. The setting at our friend\'s house was perfect, and it made the moment even more special knowing that we had the support of those closest to us. It was a day we will never forget, and we are so grateful for the love and commitment that was shown to us.'
        },
        {
            Id: '4',
            sImg: sImg4,
            sIcon:sIcon4,
            Shape:Shape,
            sTitle:'Our Engagement',
            date:'Present - March 1,2023',
            description:'We are truly blessed to have found each other and we feel so grateful to have this wonderful opportunity to start our lives together as husband and wife. With Jehovah God at the center of our relationship, we know that our love will  continue to grow stronger, and that our journey together will be filled with endless love, laughter, and adventure. We are excited to spend the rest of our lives building a beautiful and loving home, and to make memories that will last a lifetime. On this special day, we will affirm our love and commitment to each other in front of our loved ones, and we will look forward to the future with hope and excitement.'
        }
    ]



    return (

        <section className="wpo-story-section section-padding" id="story">
            <div className="container">
                <SectionTitle MainTitle={'Our Love Story'}/>
                <div className="row align-items-center justify-content-center">
                    <div className="col col-lg-12 col-12">
                        <div className="tab-area">
                            <div className="tablinks">
                                <Nav tabs className="service-thumbs-outer">
                                    {Tabs.map((tab, titem) => (
                                        <NavItem key={titem}>
                                            <NavLink
                                                className={`service-thumb ${classnames({ active: activeTab === tab.Id })}`}
                                                onClick={() => { toggle(tab.Id); }}
                                            >
                                                {tab.tabItem}
                                                <span className="number">{tab.tabNumber}</span>
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                            </div>
                            <TabContent activeTab={activeTab}>
                                {Story.map((story, Sitem) => (
                                    <TabPane tabId={story.Id} key={Sitem}>
                                        <div className="wpo-story-item">
                                            <div className="wpo-story-img">
                                                <img src={story.sImg} alt=""/>
                                            </div>
                                            <div className="wpo-story-content">
                                                <div className="wpo-story-content-inner">
                                                    {/* <span><img src={story.sIcon} alt=""/></span> */}
                                                    <h2>{story.sTitle}</h2>
                                                    <span>{story.date}</span>
                                                    <p>{story.description}</p>
                                                    <div className="border-shape">
                                                        <img src={story.Shape} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                ))}
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default StorySection;