import React from 'react'
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/event1.jpg'
import sImg2 from '../../images/event/event2.jpg'
import sImg3 from '../../images/event/event3.jpg'
import LocationMap from '../Modal'
import { Button } from 'reactstrap'


const Events = [
    {
        Simg: sImg1,
        title:'10th Year Celebration',
        li1:'Saturday, 07 October 2023 6:00 PM ',
        li2:'Centro Capital Doha Hotel',
        li3:'Al Jazeera Bin Mahmoud',
        li4:'Mezzanine Floor, Hall A',
    },
    {
        Simg: sImg2,
        title:'Let \'s make memories',
        li1:'Are you attending?',
        li2: rsvp(),
    },
    {
        Simg: sImg3,
        title:'Our Humble Request',
        li1:'Kindly arrive on time.',
        li2:'Feel free to choose any color attire other than white.',
    },

]

function rsvp(){
return (
    <a href="https://forms.gle/qHYyPxu9WhyeENB17" target="_blank"><Button>RSVP</Button></a>
)
}

const EventSection = (props) => {
    return (
        <section className={`wpo-event-section section-padding ${props.eClass}`} id="events">
            <div className="container">
                <SectionTitle MainTitle={'TIME AND PLACE'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                                <div className="wpo-event-item">
                                    <div className="wpo-event-img">
                                        <img src={event.Simg} alt=""/>
                                    </div>
                                    <div className="wpo-event-text">
                                        <h2>{event.title}</h2>
                                        <ul>
                                            <li>{event.li1}</li>
                                            <li>{event.li2}</li>
                                            <li>{event.li3}</li>
                                            <li>{event.li4}</li>
                                            <li>{event.li5}</li>
                                            <li>{event.li6}</li>

                                            {/* {event.title === "THE RECEPTION" &&
                                            <li><LocationMap/></li>
                                            } */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;